/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/extensions */

import React from "react";

import Button from "../elements/Button";
import logo from "../assets/images/logo/ATL_PNG_FF.png";

export default function BrandIcon() {
  return (
    <Button className="" type="link" href="/">
      <img src={logo} alt="Description" width="125" height="125" />
      <p className=" atl text-theme-blue text-1xl font-medium">
        Adagen
        <span className=" atl text-theme-blue">TechLab</span>
      </p>
    </Button>
  );
}
